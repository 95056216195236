import { API } from "../modules/Api.js";
export const SET_AUTH = "SET_AUTH";
export const START_LOGIN = "START_LOGIN";
export const OBTAIN_LOGIN_DATA = "OBTAIN_LOGIN_DATA";
export const OBTAIN_LOGIN_ERROR = "OBTAIN_LOGIN_ERROR";

// Login
function startLogin() {
  return { type: START_LOGIN };
}

function obtainLoginData(data) {
  return {
    type: OBTAIN_LOGIN_DATA,
    payload: data,
  };
}

function obtainLoginError(error) {
  return {
    type: OBTAIN_LOGIN_ERROR,
    payload: error,
  };
}

export function signIn(login, password, onSuccessCallback, onErrorCallback) {
  return (dispatch) => {
    dispatch(startLogin());

    let loginData = { login, password };

    return API.post("/dealer/auth", loginData).then(
      (response) => {
        console.log(response.data.token);
        localStorage.setItem(process.env.REACT_APP_AUTH_HEADER, response.data.token);
        dispatch(obtainLoginData(response.data.data));
        onSuccessCallback();
      },
      (error) => {
        dispatch(obtainLoginError(error));
        onErrorCallback();
      },
    );
  };
}

export function getUserInfo() {
  return (dispatch) => {
    return API.get("/dealer").then(
      (response) => {
        dispatch(obtainLoginData(response.data.data));
      },
      (error) => {
        dispatch(obtainLoginError(error));
      },
    );
  };
}

export function signOut() {
  return (dispatch) => {
    localStorage.clear();
    dispatch(setAuth());
  };
}

export function setAuth(force = false) {
  return {
    type: SET_AUTH,
    payload: !force ? !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER) : false,
  };
}
